import "./index.scss";

$(".hamburger").on("click", function (e) {
  if ($(".hamburger").hasClass("is-active")) {
    $(".hamburger").removeClass("is-active");
    $(".nav").removeClass("nav-active");
    // $('.header__menu').removeClass('responsive__box-active');
    $("body").removeClass("overflow");
  } else {
    $(".hamburger").addClass("is-active");
    $(".nav").addClass("nav-active");
    // $('.bg-page').addClass(' bg-page-active');
    $("body").addClass(" overflow");
  }
});

$(".nav__main nav a").on("click", function () {
  $(".hamburger").removeClass("is-active");
  $(".nav").removeClass("nav-active");
  // $('.header__menu').removeClass('responsive__box-active');
  $("body").removeClass("overflow");
});

$(".lang_header").on("click", function () {
  $(this).parents(".lang").toggleClass("lang-active");
}); 

$(document).mouseup(function (e) {
  var lang = $(e.target).parents(".lang");
  if (lang.length <= 0) {
    $(".lang").removeClass("lang-active");
  }
});
